@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

$black: #000;
$white: #fff;
$bg-grey: #d1d0d0;
$bg-green: #47a348;
$btn-color-2: #767dff;
$exchange-box-bg: #d9d9d9;
$buy-btn-color: #eb6a98;

body {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px;
  font-weight: 600;
}

a {
  text-decoration: none;
  color: $black;
}

h1 {
  font-weight: 800;
  font-size: 40px;
}

h2 {
  font-size: 34px;
  font-weight: 800;
}

h3 {
  font-size: 28px;
  font-weight: 800;
}

.bgc-grey {
  background-color: $bg-grey;
}

.bgc-green {
  background-color: $bg-green;
}

.buy-btn-bg {
  background-color: $buy-btn-color;
}

.hero-exchange-rate-box {
  max-width: 450px;
  box-shadow: 8px 8px $black;
  border: 2px solid $black;
  padding: 2px;
  box-sizing: border-box;
  background-color: $exchange-box-bg;

  .hero-exchange-header {
    height: 50px;
    width: 100%;
    background-color: #219dfc;
    border: 3px solid $black;
    color: $black;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 600;
  }
}

.hero-color-box {
  height: 400px;
  margin: auto;
  width: 500px;
  position: relative;
  box-shadow: 8px 8px $black;
}

.xchange-input-box {
  margin: auto;
  width: 95%;
  background-color: $white;
  border: 3px solid $black;
  padding: 7px 10px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.newsletter-section {
  .newsletter-form {
    box-shadow: 8px 8px $black;
    margin-top: 10px;

    .newsletter-input {
      width: 100%;
      height: 50px;
      padding: 0 20px;
      border: 2px solid $black;
    }

    .newsletter-btn {
      background: #f7931a;
      height: 50px;
      width: 200px;
      font-weight: 800;
    }
  }

  .social-media-wrapper {
    max-width: 250px;
    margin-left: auto;
    display: flex;
    justify-content: space-between;
    img {
      width: 35px;
    }
  }
}

.blog-section {
  background-color: #219dfc;
  padding: 20px 0;

  .blog-card {
    max-width: 320px;
  }
}

.app-download-section {
  .with-b-radius {
    border-radius: 40px 40px 0 0;
  }
  .app-download-wrapper {
    box-shadow: 8px 8px $black;
    padding: 20px;
    box-sizing: border-box;
    width: 100%;

    .c-t-a-text {
      font-size: 32px;
      span {
        font-weight: 800;
      }
    }
  }
}
