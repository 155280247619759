@import "variables";
@import "buttons";

.global-account-page {
  .hero {
    .image-container {
      width: 350px;
      height: 500px;
      margin: 30px auto;
      img:first-child {
        width: 250px;
        position: absolute;
        z-index: 1;
      }

      img:nth-child(2) {
        width: 200px;
        position: absolute;
        right: 0;
        bottom: 20%;
      }

      .text-box {
        width: 350px;
        // height: 100px;
        background-color: #fcb721;
        box-shadow: 6px 6px $black;
        position: absolute;
        bottom: 0;
        z-index: 2;
        padding: 10px;
        box-sizing: border-box;

        .flag-wrapper {
          display: flex;
          flex-wrap: wrap;
          //   justify-content: space-between;
          img {
            position: static;
            width: 30px;
            margin: 0 20px 10px 0;
          }
        }
      }
    }
  }

  .section-two {
    width: 100%;
    padding: 20px 50px;
    background-color: #5fc88f;
    box-sizing: border-box;
    border-radius: 20px 0 0 0;
    position: relative;

    .text-header {
      font-weight: 800;
      font-size: 20px;
    }

    img {
      width: 350px;
      position: absolute;
      top: -200px;
      right: 20%;
    }
  }
  .input-box-wrapper {
    margin: auto;
    width: 95%;
    background-color: $white;
    border: 3px solid $black;
    padding: 5px 10px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;
  }
  .section-three {
    width: 100%;
    min-height: 500px;
    position: relative;

    .C-T-A {
      width: 100%;
      border-radius: 44px 0 0 0;
      background-color: #d9d9d9;
      padding: 25px;
      box-sizing: border-box;
      margin: 20px 0;
      font-size: 35px;
      box-shadow: 8px 8px #000;

      .lady-pic {
        position: absolute;
        bottom: 0;
        width: 400px;
      }

      .text-box {
        position: absolute;
        top: 60px;
        left: 35%;
        padding: 20px;
        border-radius: 20px 0 0 0;
        background-color: #767dff;
        max-width: 600px;
        font-size: 24px;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .section-two {
    img {
      right: 0 !important;
      top: 0 !important;
    }
  }

  .section-three {
    .C-T-A {
      .lady-pic {
        position: static !important;
      }
    }

    .text-box {
      left: 0 !important;
    }
  }
}
