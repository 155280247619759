@import "variables";
@import "buttons";

.giftcard-page {
  .hero {
    .hero-img-wrapper {
      position: relative;
      img:nth-child(1) {
        width: 230px;
      }
      img:nth-child(2) {
        position: relative;
        left: -25px;
        width: 180px;
        transform: rotate(30deg);
      }
      img:nth-child(3) {
        position: absolute;
        left: 37%;
        bottom: 0;
        z-index: -1;
        width: 250px;
      }
    }

    .hero-color-box {
      margin-top: 100px;
      img:first-child {
        width: 250px;
        position: absolute;
        top: -100px;
        right: 20%;
      }
      img:nth-child(2) {
        width: 50px;
        position: absolute;
        left: -25%;
        top: 50%;
      }
      img:nth-child(3) {
        width: 50px;
        position: absolute;
        left: 2%;
        top: 60%;
      }
      img:nth-child(4) {
        width: 60px;
        position: absolute;
        left: 2%;
        top: 30%;
      }
    }
  }
  .section-two {
    width: 100%;
    background-color: #eb6a98;
    border-radius: 30px 0 0 0;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 8px 8px $black;

    .text-header {
      font-weight: 800;
      font-size: 24px;
    }

    .img-column {
      position: relative;
      img:first-child {
        position: absolute;
        bottom: 0;
        right: 30%;
        z-index: 1;
      }

      img:nth-child(2) {
        position: absolute;
        right: 5%;
        width: 250px;
      }
    }
  }

  .section-three {
    header {
      h4 {
        text-decoration: underline;
      }
    }

    .text-card {
      max-width: 350px;
      padding: 20px;
      box-sizing: border-box;
      box-shadow: 8px 8px $black;
      font-weight: 600;
      div:first-child {
        font-weight: 800;
        font-size: 24px;
      }
    }
  }
}
