@import "variables";
@import "buttons";

.qr-code-section {
  width: 100%;
  height: 100vh;
  background-color: rgba($color: #000000, $alpha: 0.3);
  position: fixed;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10000;

  .qr-code-modal {
    width: 1000px;
    background-color: $white;
    padding: 30px;
    box-sizing: border-box;
    box-shadow: 8px 8px $black;

    img {
      cursor: pointer;
    }

    .qr-code-box {
      width: 400px;
      height: 400px;
      font-weight: 400;
    }

    .qr-code-text {
      max-width: 300px;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 15px;

      span {
        font-size: 32px;
        font-weight: 600;
      }
    }
  }
}
