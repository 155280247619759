@import "variables";

nav {
  display: grid;
  grid-template-columns: 20% 54% 15% 11%;
  align-items: center;
  border-bottom: 4px solid $black;
  a {
    font-weight: 600;
    &:first-child {
      justify-self: end;
    }
  }

  .menu-list {
    justify-self: end;
    width: 70%;
    justify-content: space-evenly;
    a {
      font-size: 20px;
    }
  }

  .telegram-link {
    border-left: 4px solid $black;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }

  .download-link {
    background-color: $black;
    color: $white;
    height: 100%;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 600;
    cursor: pointer;
  }
  .product-nav-content {
    position: absolute;
    padding: 20px;
    top: 80px;
    background-color: $white;
    z-index: 100;
    width: 250px;
    box-sizing: border-box;
    border-radius: 5px;

    ul {
      margin: 0;
      li {
        font-size: 14px;
        margin-bottom: 5px;
      }
      li:last-child {
        margin-bottom: 0;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  nav {
    .menu-list {
      a {
        font-size: 14px !important;
      }
    }

    .download-link,
    .telegram-link {
      font-size: 14px !important;
      text-align: center !important;
    }
  }
}
