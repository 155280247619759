@import "variables";
@import "buttons";

.about-page {
  .img-wrapper {
    img:nth-child(1) {
      z-index: 10;
      position: relative;
    }
    img:nth-child(2) {
      position: absolute;
      left: 34%;
      top: 20%;
      z-index: 1;
    }

    img:nth-child(3) {
      position: absolute;
      bottom: 10%;
      left: 40%;
      width: 250px;
      z-index: -1;
    }
  }

  .robot-img {
    position: absolute;
    bottom: -30%;
    right: 10%;
    width: 200px;
  }
  .statement-details {
    background-color: #fcb721;
    padding: 20px;
    box-sizing: border-box;
    box-shadow: 6px 6px $black;
    font-size: 20px;
    width: 450px;

    .header {
      font-size: 24px;
      font-weight: 800;
    }
  }
}
