@import "variables";
@import "buttons";

.blog-page {
  .hero {
    width: 100%;
    padding: 5px;
    min-height: 600px;
    box-sizing: border-box;
    background-color: #d9d9d9;
    box-shadow: 8px 8px $black;
    border: 2px solid $black;
    .hero-img {
      background-position: center;
      background-size: cover;
      width: 100%;
      height: 500px;
    }

    .blog-content {
      width: 100%;
      box-sizing: border-box;
      padding: 10px;
    }
    .blog-title {
      text-align: center;
      font-weight: 800;
      font-size: 30px;
    }
  }
}
.blog-img {
  height: 200px;
  max-height: 200px;
}
